:root {
  --e-global-color-accent: #2270E2;
  --e-global-color-secondary: #FFFFFF;
  --e-global-color-primary: #212121;
  --e-global-color-text: #4D4D4D;
  --e-global-color-cac7139: #154B9B;
  --e-global-color-c742624: #FFFFFF;
  --e-global-color-e0b99f2: #FFFFFF;
  --e-global-color-45d598c: #FFFFFF;
  --e-global-color-40b28bc: #000000;
  --e-global-color-95d2432: #F4F8FD;
  --e-global-color-0bbe810: #F3F4F6;
  --e-global-color-cfcb5bb: #222222;
  --e-global-color-eb9def8: #ECF5F1;
  --e-global-color-fab3c72: #49A078;

  --e-global-typography-primary-font-family: "Poppins";
  --e-global-typography-primary-font-size: 45px;
  --e-global-typography-primary-font-weight: 700;
  --e-global-typography-primary-line-height: 1.1em;
  --e-global-typography-secondary-font-family: "Poppins";
  --e-global-typography-secondary-font-size: 30px;
  --e-global-typography-secondary-font-weight: 700;
  --e-global-typography-secondary-line-height: 1.6em;
  --e-global-typography-text-font-family: "Poppins";
  --e-global-typography-text-font-size: 14px;
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-text-line-height: 1.8em;
  --e-global-typography-accent-font-family: "Poppins";
  --e-global-typography-accent-font-size: 14px;
  --e-global-typography-accent-font-weight: 600;
  --e-global-typography-0e838b8-font-family: "Poppins";
  --e-global-typography-0e838b8-font-size: 30px;
  --e-global-typography-0e838b8-font-weight: 500;
  --e-global-typography-0e838b8-line-height: 36px;
  --e-global-typography-48643ca-font-family: "Poppins";
  --e-global-typography-48643ca-font-size: 25px;
  --e-global-typography-48643ca-font-weight: 700;
  --e-global-typography-4bf5b7c-font-family: "Poppins";
  --e-global-typography-4bf5b7c-font-size: 20px;
  --e-global-typography-4bf5b7c-font-weight: 700;
  --e-global-typography-4bf5b7c-line-height: 1.3em;
  --e-global-typography-f1e264a-font-family: "Poppins";
  --e-global-typography-f1e264a-font-size: 15px;
  --e-global-typography-f1e264a-font-weight: 600;
  --e-global-typography-f1e264a-text-transform: uppercase;
  --e-global-typography-40db593-font-family: "Poppins";
  --e-global-typography-40db593-font-size: 16px;
  --e-global-typography-40db593-font-weight: 700;
  --e-global-typography-40db593-line-height: 1.2em;
  --e-global-typography-4ed90d5-font-family: "Poppins";
  --e-global-typography-4ed90d5-font-size: 16px;
  --e-global-typography-4ed90d5-font-weight: 600;
  --e-global-typography-4ed90d5-line-height: 1.3em;
  --e-global-typography-9cfb7c3-font-family: "Poppins";
  --e-global-typography-9cfb7c3-font-size: 16px;
  --e-global-typography-9cfb7c3-font-weight: 500;
  --e-global-typography-9cfb7c3-line-height: 1.2em;
}

.app-font-h1 {
  font-size: calc(20px + 2vw) !important;
}

.app-font-h2 {
  font-size: calc(14px + 1.4vw) !important;
}

.app-font-h3 {
  font-size: calc(12px + 1.2vw) !important;
}

.app-font-h4 {
  font-size: calc(10px + 1vw) !important;
}

.app-font-h5 {
  font-size: calc(8px + 0.8vw) !important;
}

.app-font-body {
  font-size: calc(6px + 0.6vw) !important;
}

.responsive-font-h1 {
  font-size: clamp(12px, 4vw, 32px);
}

.app-title {
  /* color: var(--app-color);
  color: black;
  text-decoration: none;
  font-size: calc(20px + 5vw); */
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.app-color {
  color: teal
}

.app-navbar {
  height: 70px;
  position: fixed;
}

.app-body1 {
  padding-top: 70px;
}

.app-navbar-extended-nav {
  height: 150px;
  position: fixed;
}

.app-body-extended-nav {
  padding-top: 150px;
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
/* Scroll bar */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: teal white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: teal;
  border-radius: 50%;
  border: 1px solid white;
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */

.text-overflow-ellipsis {
  width: 100%;
  /* Set your desired width */
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide overflowing text */
  text-overflow: ellipsis;
}

.text-overflow-ellipsis:hover {
  overflow: visible;
  /* Show overflowing text on hover */
  white-space: normal;
  /* Allow text to wrap */
  word-wrap: break-word;
}

.cursor-pointer{
  cursor: pointer;
}

