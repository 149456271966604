.top-nav-button{
    color:teal;
    /* font-weight:bolder  !important; */
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.top-nav-button:hover{
    background-color:rgba(200,200,200, 0.5);
    color:teal;
}

.top-nav-button-selected{
    background-color:rgba(200,200,200, 0.5);
    background-color: teal;
    color:whitesmoke;
    padding-left:15px !important;
    padding-right:15px !important;
    /* font-weight: bolder !important; */
    /* margin-left: 20px !important;
    margin-right: 20px !important; */
}