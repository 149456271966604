.home-banner-bg {
    background-color: #f0f0f0;
    /* Fallback color */
    background-image: url('../CommonComponents/ImagesComponent/StaticImages/cat_bg.png');
    background-size: cover;
    /* Ensure image covers the entire element */
    background-position: center;
    /* Centers the image */
    background-repeat: no-repeat;
    /* Avoid repeating the image */
}

@media (max-width: 768px) {
    .home-banner-bg {
        background-size: contain;
        /* Changes to contain for smaller screens */
    }
}

.homepage-container {
    position: relative;
    height: 800px;
    background: linear-gradient(135deg, #00797C 0%, #00B6AD 100%);
    /* background-color: teal; */
    /* Black background */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* Styles for top-left image */
.image-top-left {
    position: absolute;
    top: 50px;
    left: 40px;
    width: 300px;
    height: 250px;
    border: 5px solid white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transform: rotate(-30deg);
    /* Random tilt */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-top-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-top-left:hover {
    transform: rotate(0deg);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

/* Styles for top-right image */
.image-top-right {
    position: absolute;
    top: 20px;
    right: 50px;
    width: 300px;
    height: 300px;
    border: 5px solid white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transform: rotate(10deg);
    /* Random tilt */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-top-right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-top-right:hover {
    transform: rotate(0deg);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

/* Styles for bottom-left image */
.image-bottom-left {
    position: absolute;
    bottom: 60px;
    left: 20px;
    width: 270px;
    height: 270px;
    border: 5px solid white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transform: rotate(25deg);
    /* Random tilt */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-bottom-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-bottom-left:hover {
    transform: rotate(0deg);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

/* Styles for bottom-right image */
.image-bottom-right {
    position: absolute;
    bottom: 60px;
    right: 50px;
    width: 320px;
    height: 280px;
    border: 5px solid white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    transform: rotate(-20deg);
    /* Random tilt */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-bottom-right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-bottom-right:hover {
    transform: rotate(0deg);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

.center-text {
    position: relative;
    text-align: center;
    color: white;
    font-family: 'Montserrat', sans-serif;
    /* Font family Montserrat */
}

.center-heading {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1.2;
    letter-spacing: 1px;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5), -2px -2px 3px rgba(255, 255, 255, 0.2);
    /* Character-wise shadow */
    margin-bottom: 20px;
}

.search-bar {
    width: 70%;
}

.search-button {
    margin-left: 10px;
    padding: 15px;
}